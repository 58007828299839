import React from "react";
import styled from "styled-components";
import "../components/layout.css"


import { COLORS } from "../../style-vars.js";

import NewShape from "../assets/forma.svg";


class ConfirmationPage extends React.Component {

  render() {
    return (
      <Section>
        <ConfirmationDiv>
          <GreenShape src={NewShape} />
          <ConfirmationText>
            <h2>Your subscription was successfully confirmed!</h2>
            <p> Click bellow to download the catalogue.</p>
          </ConfirmationText>
          <DownloadButton>
            Download Catalogue
                    </DownloadButton>
        </ConfirmationDiv>
      </Section>
    );
  }
}

export default ConfirmationPage;

const Section = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const ConfirmationText = styled.div`
    z-index: 5;
    color: ${COLORS.DARK_GREY};
    font-family: "Lato", sans-serif;

`

const ConfirmationDiv = styled.div`
    width: 600px;
    height: 400px;
    padding: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 0px 16px 0px rgba(0,0,0,.3);
    border-radius: 6px;

`
const DownloadButton = styled.button`
  background-color: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  align-self: center;
  width: fit-content;
  border-radius: 16px;
  border: none;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 24px;
  cursor: pointer;

  @media (max-width: 569px){
    position: initial;
    margin-bottom: 64px;  
  }
`

const GreenShape = styled.img`
  width: 200px;
  position: absolute;
  left: 0;
  opacity: 0.4;

  @media (max-width: 1024px) {
    width: 150px;
    margin-top: 40px;
  }
  @media (max-width: 600px) {
    width: 100px;
    margin-top: 40px;
  }
  @media (max-width: 414px) {
    width: 60px;
    margin-top: 20px;
  }
`;